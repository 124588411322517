.logo:hover {
  cursor: pointer;
}

#header ul {
  display: inline-flex;
}

#header ul li {
  list-style-type: none;
} 

.main {
  max-width: 95%;
  margin: auto; 
}

.carousel-q-container {
  width: 100%; 
}

.multi-line-text2 {  
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden;
  height: 2.5rem;
}

.multi-line-text2-adm {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  height: 0.75rem;
}

.multi-line-tes-adm {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 2.5rem;
}
 
.multi-line-text {
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 2.75rem;
}

.multi-line-art {
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  height: 30px;
}

.main-admin {
  min-width: 1200px; 
  margin: auto; 
}

.main-adminXL {
  max-width: 1440px;
  margin: auto;
}

input:hover {
  cursor:  pointer;
}

.sun-editor .se-wrapper {
  z-index: 0 !important;
}

.sun-editor .se-toolbar {
  z-index: unset !important;
}

.box-preview {
  height: 375px;
  background-color: #ffff;
  border: 1px solid lightgrey;
  overflow: auto;
}

.box-preview span {
  line-height: 24px;
  letter-spacing: 0.02em;
}

.shape-style-circle {
  margin: auto;
  width: 4rem;
  border-radius: 4rem;
}

.shape-style-rectangular {
  width: 100%;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-in-top {
  -webkit-animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-left {
  -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
} 

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.box-image-zoom {
  overflow: hidden;
}
.box-image-zoom img { 
  transition: transform .5s ease;
}
.box-image-zoom:hover img {
  transform: scale(1.5);
}

/* Custom Scrollbar */
.custom-scrollbar {
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
.custom-scrollbar.x {
  overflow-x: auto;
}
.custom-scrollbar.y {
  overflow-y: auto;
}
.custom-scrollbar.x::-webkit-scrollbar {
  height: 10px !important;
}
.custom-scrollbar.y::-webkit-scrollbar {
  width: 10px !important;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 1rem; 
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 1rem;
}
